import React, { Fragment, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SelectableContext from '../../../contexts/local/selectable/SelectableContext';
import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';
import StatusBarButton from '../../layout/StatusBarButton';
import Loading from '../../ui-elements/Loading';
import Channel from '../discarding/panel/Channel';
import Panel from '../discarding/panel/Panel';
import PlantBlockSelectable from '../discarding/panel/PlantBlockSelectable';
import SelectionSidebar from '../../layout/Sidebar/SelectionSidebar';
import BackButton from '../../layout/BackButton';


const PanelSlotSelection = ({ forwardTo, backwardTo, harvesting }) => {
  const { additionalProcessInformation, loadOccupancy, occupancyArray, continueProcess, activeProcessId } =
    useContext(ProcessContext);
  const { loading } = useContext(UIContext);
  const { selection, Sidebar } = useContext(SelectableContext);

  const navigate = useNavigate();

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    await loadOccupancy(additionalProcessInformation.sourceEntity, 'panel');
  };

  const proceedToConfirmation = async () => {
    try {
      await continueProcess(activeProcessId, selection);
      navigate(forwardTo);

    } catch (error) {
      console.error(error);
    }
  };

  return loading ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      {Sidebar}
      <BackButton disabled={false} navigateTo={backwardTo} />
      <Panel alignment='horizontal'>
        {occupancyArray.map((channel) => (
          <Channel key={channel.channelNumber} channel={channel}>
            {channel.plantSlotBlocks.map((block, index) => (
              <PlantBlockSelectable
                key={`${channel.channelNumber}-${block.startPlantSlotOrdinalNumber}-${block.endPlantSlotOrdinalNumber}`}
                block={block}
                channelNr={channel.channelNumber}
                index={index}
                disabled={!block.isOccupied}
                hideContent={!block.isOccupied}
                endOfLife={additionalProcessInformation?.endOfLife}
                style={{
                  width: block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber + 1 + '%',
                }}
                harvesting={harvesting}
              />
            ))}
          </Channel>
        ))}
      </Panel>
      {selection.length > 0 && (
        <Fragment>
          <StatusBarButton
            label='Continue'
            icon='fas fa-chevron-right'
            reversed={true}
            clickHandler={proceedToConfirmation}
            statusSlot={5}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default PanelSlotSelection;
