import React from 'react';
import Button from './Button';
const BooleanComponent = ({ clickHandler }) => {
  return (
    <div className='boolean'>
      <Button className='yes' clickHandler={() => clickHandler(0)} label='YES' icon='fas fa-thumbs-up' />
      <Button className='no' clickHandler={() => clickHandler(1)} label='NO' icon='fas fa-thumbs-down' />
    </div>
  );
};

export default BooleanComponent;
