const TicketInformation = ({summary, reference, createdAt, status, newTicket}) => {
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return(
        <div className={`ticket-information ${newTicket ? "mark-as-new" : ""}`}>
            <i class="fa-solid fa-note-sticky ticket-icon"></i>
            <span className="summary">{summary}</span>
            <span className="details">{reference} · {formatDate(createdAt)} · {status}</span>
        </div>
    )
}

export default TicketInformation;