import { useContext, useState } from "react"
import SelectableContext from "../../../contexts/local/selectable/SelectableContext";

const SelectionSidebar = ({selectAll, reset}) => {

  const [isOpen, setIsOpen] = useState(false);
  // const {selectAll, reset} = useContext(SelectableContext);

  const onClickSelectAll = (event) => {
    event.stopPropagation();
    selectAll();
  }

  const onClickReset = (event) => {
    event.stopPropagation();
    reset();
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  }

  return isOpen ? (<><div className='sidebar-container-open' onClick={toggleOpen}>
  <div className='select-icon' onClick={onClickSelectAll}><i className="fair fai-select-all"></i></div>
  <div className='reset-icon' onClick={onClickReset}><i className="fair fai-deselect-all"></i></div>
</div></>) : (<>
  <div className='sidebar-container' onClick={toggleOpen}>
    <div className='closed-sidebar-text'>Selection Tools</div>
  </div>
</>)
}

export default SelectionSidebar;