class FarmerAppApiErrorMapper {

  mapFarmerAppError(e) {
    if (!e) return;
    const statusCode = e.response?.status;
    const internalStatusCode = e.response?.data?.code;
    const errorMessage = e.response?.data?.message;
    const dataKeys = Object.keys(e.response?.data?.data);
    const failingPropertyName = dataKeys[0];
    const devErrorMessage = e.response?.data?.data[failingPropertyName];

    const error = {
        statusCode,
        internalStatusCode,
        errorMessage,
        failingPropertyName,
        devErrorMessage
    };

    return error;
  }
}

export default new FarmerAppApiErrorMapper();