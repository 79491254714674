import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';
import AuthContext from '../../../contexts/auth/AuthContext';
import WeighingPanel from './WeighingPanel';
import WeighingTray from './WeighingTray';
import Loading from '../../ui-elements/Loading';
import StatusBarButton from '../../layout/StatusBarButton';
import BackButton from '../../layout/BackButton';
import Scale from './Scale';
import { ENABLE_PRINTING, DISABLE_PRINTING, REPRINT, MULTIHARVEST } from '../../../contexts/types/processControlTypes';
import { HttpAgent } from '../../../utility/HttpAgent';
import { v4 as uuidv4 } from 'uuid';
import { HELP_DESK_LINK } from '../../../constants/helpDesk';

const WeighingContainer = ({ enablePrinting = true, processType = 'HARVESTING', backwardTo }) => {
  const {
    activeProcessId,
    updateOccupancy,
    continueProcess,
    controlProcess,
    additionalProcessInformation,
    loadOccupancy,
    notifySuccess,
    notifyError,
    requestedInformationType,
    occupancyArray,
  } = useContext(ProcessContext);
  const { loading } = useContext(UIContext);
  const { currentTenant, currentContainer } = useContext(AuthContext);
  const [cancelled, setCancelled] = useState(false);
  const [refTimestamp, setRefTimestamp] = useState(new Date());
  const [withPrint, setWithPrint] = useState(enablePrinting);
  const [printed, setPrinted] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [cameraState, setCameraState] = useState(false);
  const [analysisDataFlag, setAnalysisDataFlag] = useState(false);
  const [weighedPlantDisplayText, setWeighedPlantDisplayText] = useState('');
  const [plantBlockBeingWeighed, setPlantBlockBeingWeighed] = useState(null);
  const [backIsForbidden, setBackIsForbidden] = useState(false);

  const weightEndpointUrl = `/Api/V1/Tenants/${currentTenant?.tenantId}/Containers/${currentContainer?.id}/Scales/${additionalProcessInformation?.scaleId}/LatestWeightMeasurement`;
  const cameraStateUrl = `/Api/V1/Tenants/${currentTenant?.tenantId}/Containers/${currentContainer?.id}/Devices/${currentContainer.weighingCameraId}/State`;

  const initialWeighingData = {
    weight: 0,
    timestamp: new Date(),
    alibiIdentifier: '',
    firstTime: true,
    disabled: true,
    operationId: '',
    weighingCameraOperationId: ''
  };
  const [weighingData, setWeighingData] = useState(initialWeighingData);

  const initialWeighingOperationState = {
    currentWeighingOperationId: null,
    currentWeighingCameraOperationId: null
  };
  const weighingOperationState = useRef(initialWeighingOperationState);  

  useEffect(() => {
    resetReferenceTimestamp();
    load();
    updateCameraState();

    return () => {
      weighingOperationState.current = initialWeighingOperationState;
      updateOccupancy([]);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => await weigh(), 500);

    let channelIndex = Math.floor(additionalProcessInformation?.plantSlotOrdinalNumber / 100);

    if (additionalProcessInformation?.sourceEntity.toLowerCase().startsWith('p') && occupancyArray[channelIndex] != undefined) {
      let elementBeingWeighed = occupancyArray[channelIndex].plantSlotBlocks.filter(
        (block) => block.startPlantSlotOrdinalNumber === additionalProcessInformation?.plantSlotOrdinalNumber
      );
      setPlantBlockBeingWeighed(elementBeingWeighed);
    };
    return () => {
      clearInterval(interval);
    };
  }, [additionalProcessInformation, refTimestamp, cameraState, analysisDataFlag]);

  useEffect(() => {
    if (!weighingData.operationId) {
      return;
    }

    const weighingOperationId = weighingData?.operationId;

    const newState = {
      currentWeighingOperationId: weighingOperationId,
      currentWeighingCameraOperationId: null
    };
    weighingOperationState.current = newState;

    if (!weighingOperationState?.current.currentWeighingOperationId) {
      return;
    }

    if (!weighingOperationState?.current.currentWeighingOperationId && !weighingOperationState?.currentWeighingCameraOperationId) {
      return;
    }
    if (cameraState) {
      getWeighingCameraOperationId(weighingOperationState?.current.currentWeighingOperationId);
    } else {
      setDisableConfirm(false);
    }
  }, [weighingData]);

  const createWeighingBody = () => {
    return {
      weight: weighingData.weight,
      alibiIdentifier: weighingData.alibiIdentifier,
      timestamp: weighingData.timestamp,
      weighingCameraOperationId: weighingOperationState?.current.currentWeighingCameraOperationId,
      weighingOperationId: weighingData.operationId
    };
  };

  const weigh = async () => {
    if (cancelled) return;

    try {
      const data = await pollScale();
      const { weight, timeStamp, alibiIdentifier, operationId } = data;

      const weighingOperationTimestamp = new Date(timeStamp);
      const canUpdate = refTimestamp.getTime() < weighingOperationTimestamp.getTime();

      if (canUpdate) {
        updateWeighingData({ weight, timestamp: weighingOperationTimestamp, alibiIdentifier, operationId });
        setRefTimestamp(weighingOperationTimestamp);
      }
    } catch (error) {
      notifyError(<>We have encountered an issue on our side while getting the weight. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error(error);
      updateWeighingData({ weight: 0, timestamp: new Date(), alibiIdentifier: '', operationId: '', weighingCameraOperationId: '' });
      setDisableConfirm(true);
    }
  };

  const getWeighingCameraOperationId = async (operationId) => {
    const pollId = uuidv4();
    const myOperationId = operationId;

    if (cameraState) {
      try {
        for (let index = 0; index < 10; index++) {
          if (weighingOperationState?.current.currentWeighingOperationId !== operationId) {
            console.log(JSON.parse(JSON.stringify(pollId)) + "// Cancelling operation with id: ", JSON.parse(JSON.stringify(myOperationId)));
            return;
          }

          let weighingCameraOperationIds = await getWeighingCameraOperationIds(operationId);

          if (weighingCameraOperationIds.length !== 0) {
            let weighingCameraOperationId = weighingCameraOperationIds[0];

            const newState = {
              currentWeighingOperationId: myOperationId,
              currentWeighingCameraOperationId: weighingCameraOperationId
            };

            weighingOperationState.current = newState;
            return;
          }

          if (index === 9 && analysisDataFlag === false) {
            notifyError(<>We're unable to retrieve analysis data from the weighing camera. You can still proceed with your operations; however, please be aware that only limited analysis data will be recorded for the current plant under these circumstances. If this warning occurs more frequently, please contact the support team {HELP_DESK_LINK}.</>);
            setAnalysisDataFlag(true);
            break;
          }

          await wait(weighingCameraOperationIds);
        }
      } catch (error) {
        notifyError(<>We have encountered an issue on our side while getting the weighing camera operation id. Please contact the support team {HELP_DESK_LINK}.</>);
        console.log(error.message);
      } finally {
        if (weighingOperationState?.current.currentWeighingOperationId === operationId) {
          setDisableConfirm(false);
        }
      }
    }
  };

  const getWeighingCameraOperationIds = async (weighingOperationId) => {
    const id = await HttpAgent.get(`/api/v1/devices/${currentContainer.weighingCameraId}/WeighingCameraOperations?weighingOperationId=${weighingOperationId}`);
    return id.data;
  };

  const handleConfirm = async (event, multiHarvest = false) => {
    try {
      setBackIsForbidden(true);
      setCancelled(true);
      if (withPrint) {
        setPrinted(false);
        await continueProcess(activeProcessId, { ...createWeighingBody(), multiHarvest, operationId: weighingOperationState?.current.currentWeighingCameraOperationId });
      } else {
        setPrinted(true);
        await continueProcess(activeProcessId, { ...createWeighingBody(), multiHarvest, operationId: weighingOperationState?.current.currentWeighingCameraOperationId });
      }

      resetWeighingData();
      resetWeighingOperationState();
      resetReferenceTimestamp();
    } catch (error) {
      notifyError(<>We have encountered an issue on our side while confirming the process. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error(error);
    } finally {
      setAnalysisDataFlag(false);
      setDisableConfirm(true);
    }
  };

  const handleMultiharvest = async () => {
    try {
      setBackIsForbidden(true);
      setCancelled(true);
      if (withPrint) {
        setPrinted(false);
        await controlProcess(activeProcessId, MULTIHARVEST, true, 'Successfully harvested from plant.', createWeighingBody());
      } else {
        setPrinted(true);
        await controlProcess(activeProcessId, MULTIHARVEST, true, 'Successfully harvested from plant.', createWeighingBody());
      }

      resetWeighingData();
      resetReferenceTimestamp();
    } catch (error) {
      notifyError(<>We have encountered an issue on our side while modifying the process. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error(error);
    } finally {
      setDisableConfirm(true);
    }
  };

  const handlePrint = async () => {
    try {
      setPrinted(true);
      var res = await continueProcess(activeProcessId, { ...createWeighingBody(), operationId: weighingOperationState?.current.weighingCameraOperationId });
      if (withPrint) {
        notifySuccess('Label printed');
      }
    } catch (error) {
      notifyError(<>We have encountered an issue on our side while printing the label. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error(error);
    }
  };

  const handleReprint = async () => {
    try {
      await controlProcess(activeProcessId, REPRINT, true, 'Label printed', createWeighingBody());
    } catch (error) {
      notifyError(<>We have encountered an issue on our side while reprinting. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error(error);
    }
  };

  const load = async () => {
    if (additionalProcessInformation) {
      await loadOccupancy(additionalProcessInformation.sourceEntity, 'panel');
    }
  };

  const pollScale = async () => {
    const data = await HttpAgent.get(weightEndpointUrl);
    return data.data;
  };

  const resetWeighingData = () => {
    setWeighingData(initialWeighingData);
    setCancelled(false);
  };

  const resetWeighingOperationState = () => {
    if (weighingOperationState?.current !== null) {
      weighingOperationState.current = initialWeighingOperationState;
    }
  };

  const toggleWithPrint = async () => {
    let actionType = withPrint ? DISABLE_PRINTING : ENABLE_PRINTING;
    let successMessage = `Printing set to ${withPrint ? 'off' : 'on'}`;

    try {
      await controlProcess(activeProcessId, actionType, true, successMessage);

      if (weighingData.firstTime) {
        setPrinted(false);
      } else {
        setPrinted(!withPrint);
      }

      setWithPrint(!withPrint);
      setWeighingData({ ...weighingData, firstTime: false });
    } catch (error) {
      notifyError(<>We have encountered an issue on our side while toggling the print option. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error(error);
    }
  };

  const updateCameraState = () => {
    HttpAgent.get(cameraStateUrl).then((response) => {
      const newState = response.data ? true : false;
      setCameraState(newState);
    });
  };

  const resetReferenceTimestamp = () => {
    const now = new Date();
    setRefTimestamp(now);
  };

  const updateWeighingData = (data) => {
    setWeighingData({ ...weighingData, ...data });
  };

  function wait() {
    return new Promise(resolve => setTimeout(resolve, 100));
  };

  const renderDependingOnAnalysisState = () => {
    return (
      <Fragment>
        <BackButton disabled={backIsForbidden} navigateTo={backwardTo} />
        {additionalProcessInformation && !loading ? (
          additionalProcessInformation.sourceEntity.toLowerCase().startsWith('t') ? (
            <WeighingTray setScaleTitle={setWeighedPlantDisplayText} />
          ) : (
            <WeighingPanel 
              setScaleTitle={setWeighedPlantDisplayText}
            />
          )
        ) : (
          <Loading fullScreen />
        )}
        <Scale
          title={weighedPlantDisplayText}
          weighingData={weighingData}
          resetData={resetWeighingData}
          refTimeStamp={refTimestamp}
          cancelled={cancelled}
          setCancelled={setCancelled}
          update={updateWeighingData}
          element={plantBlockBeingWeighed}
          layout={
            additionalProcessInformation && additionalProcessInformation.sourceEntity.toLowerCase().startsWith('t')
              ? 'scale-tray'
              : 'scale'
          }
        />
        {renderControlButtons()}
      </Fragment>
    );
  };

  const renderControlButtons = () => {
    if (requestedInformationType === 13) {
      return (
        <StatusBarButton
          label='Print'
          icon='fas fa-print'
          clickHandler={handlePrint}
          type='inline'
          statusSlot={5}
          disabled={!weighingData.weight}
          dropdown={true}
          switchValue={withPrint}
          dropdownSwitch={toggleWithPrint}
          onText='Disable printing'
          offText='Enable printing'
        />
      );
    }

    if (requestedInformationType === 14) {
      return (
        <Fragment>
          {additionalProcessInformation?.printing && (
            <StatusBarButton
              label='Reprint'
              icon='fas fa-print'
              clickHandler={handleReprint}
              type='inline'
              statusSlot={2}
              disabled={!weighingData.weight}
            />
          )}
          {additionalProcessInformation?.isMultiharvesting && (
            <StatusBarButton
              label={'Confirm &\nHarvest Again'}
              icon='fas fa-redo'
              reversed={true}
              clickHandler={handleMultiharvest}
              statusSlot={4}
              disabled={!weighingData?.weight || disableConfirm}
              size='s'            
            />
          )}
          <StatusBarButton
            label={plantBlockBeingWeighed[0]?.isMultiharvestable ? 'Confirm &\nHarvest Next' : 'Confirm'}
            icon='fas fa-check'
            reversed={true}
            clickHandler={handleConfirm}
            statusSlot={5}
            disabled={!weighingData?.weight || disableConfirm}
            dropdown={true}
            switchValue={withPrint}
            dropdownSwitch={toggleWithPrint}
            onText='Disable printing'
            offText='Enable printing'
            size={plantBlockBeingWeighed[0]?.isMultiharvestable ? 's' : ''}
          />
        </Fragment>
      );
    }

    if (requestedInformationType == 4) {
      return (
        <Fragment>
          <StatusBarButton
            label='Confirm'
            icon='fas fa-check'
            reversed={true}
            clickHandler={handleConfirm}
            statusSlot={5}
            disabled={!weighingData.weight || disableConfirm}
          />
        </Fragment>
      );
    }
  };

  return renderDependingOnAnalysisState();
};

export default WeighingContainer;
