import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../../../contexts/process/ProcessContext';
import UIContext from '../../../../contexts/ui/UIContext';
import StatusBarButton from '../../../layout/StatusBarButton';
import Loading from '../../../ui-elements/Loading';
import TraySlot from './TraySlot';

const NursingTable = () => {

  const {
    activeProcessId,
    continueProcess,
    loadOccupancy,
    occupancyArray,
    setSelection,
    updateOccupancy,
    selection,
    additionalProcessInformation,
  } = useContext(ProcessContext);

  const { loading } = useContext(UIContext);
  const navigate = useNavigate();

  const load = async () => {
    await loadOccupancy('s1', 'panel');
  };
  useEffect(() => {
    load();
  }, []);

  const [confirmed, setConfirmed] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(undefined);
  const [copyArray, setCopyArray] = useState([]);
  useEffect(() => {
    setCopyArray([...occupancyArray]);
  }, [occupancyArray]);

  const handleSelect = (clickedSlot) => {
    if (clickedSlot.occupancy === additionalProcessInformation.sourceEntity) return;

    const prevSlot = copyArray.find(
      (slot) => slot.occupancy === additionalProcessInformation.sourceEntity
    );

    clickedSlot.occupancy = additionalProcessInformation.sourceEntity;
    if (prevSlot) prevSlot.occupancy = 'available';

    setSelectedSlot(clickedSlot);
    setCopyArray((prev) =>
      prev.map((slot) => {
        if (prevSlot && slot.ordinalNumber === prevSlot.ordinalNumber) return prevSlot;
        if (slot.ordinalNumber === clickedSlot.ordinalNumber) return clickedSlot;

        return slot;
      })
    );
  };

  const confirmSelection = async () => {
    try {
      setConfirmed(true);
      await continueProcess(activeProcessId, selectedSlot.ordinalNumber);
      navigate('/');

    } catch (error) {
      console.error(error)
    }
  };

  return loading && confirmed ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      <div className='reposition-tray-container'>
        <div className='tray-slot-container'>
          {copyArray.map((unit, index) => {
            return (
              <TraySlot
                key={unit.ordinalNumber}
                unit={unit}
                cols={8}
                index={index}
                isSelected={selectedSlot?.ordinalNumber === unit.ordinalNumber}
                handleClick={() => handleSelect(unit)}
              />
            );
          })}
        </div>
      </div>
      <StatusBarButton label='Confirm' icon='fas fa-check' reversed={true} type='inline' clickHandler={confirmSelection} statusSlot={5} />
    </Fragment>
  );
};

export default NursingTable;
